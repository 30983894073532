<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="8"
        lg="6"
      )
        h1.text-h4.mb-4 My Profile
        v-card(
          outlined
        )
          v-card-text
            FeathersVuexFormWrapper(
              :item="item"
            )
              template(v-slot="{ clone, save, reset, isDirty }")
                v-form(
                  @submit.prevent="save().then(handleSaveReponse)"
                  ref="form"
                  v-model="valid"
                )
                  v-row
                    v-col
                      v-text-field(
                        label="Username"
                        filled
                        v-model="clone.username"
                        :rules="[rules.required]"
                      )
                  v-row
                    v-col
                      v-text-field(
                        :label="isNew ? 'Password' : 'Update Password'"
                        :type="isEdit ? 'text' : 'password'"
                        filled
                        v-model="clone.password"
                        :rules="isNew ? [rules.required] : []"
                      )
                  v-row
                    v-col
                      v-text-field(
                        label="Email"
                        filled
                        v-model="clone.email"
                        :rules="[rules.email]"
                      )
                  v-row
                    v-col
                      v-text-field(
                        label="First Name"
                        filled
                        v-model="clone.firstName"
                        :rules="[rules.required]"
                      )
                    v-col
                      v-text-field(
                        label="Last Name"
                        filled
                        v-model="clone.lastName"
                        :rules="[rules.required]"
                      )
                  v-row
                    v-col
                      v-text-field(
                        label="Phone"
                        filled
                        type="tel"
                        v-model="clone.phone"
                      )

                  v-row
                    v-col
                      v-btn(
                        color="secondary"
                        type="submit"
                        block
                        height="50px"
                        :disabled="!valid || !isDirty"
                      ) Save
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex'
import validations from '@/mixins/validationRulesMixin'

export default {
  name: 'My Profile',
  components: {
    FeathersVuexFormWrapper
  },
  mixins: [
    validations
  ],
  computed: {
    item () {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    handleSaveReponse (savedUser) {
      this.$snackSuccess('Profile Updated')
    },
    cancel () {
      if (this.id === 'new') {
        this.$router.push({ name: 'techs' })
      } else {
        this.$router.push({ name: 'tech', params: { techId: this.id } })
      }
    }
  }
}
</script>
